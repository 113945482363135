import * as storage from '../storage/storage';
// For participation in tests defined in abtestDefinitions.ts
const PARTICIPATIONS_KEY = 'abParticipations';
// For participation in landing page tests, which are passed through from the server
const LANDING_PAGE_PARTICIPATIONS_KEY = 'landingPageParticipations';
function getSessionParticipations(key) {
    const participations = storage.getSession(key);
    if (participations) {
        try {
            return JSON.parse(participations);
        }
        catch (error) {
            console.error(`Failed to parse ${key} from session storage`, error);
            return undefined;
        }
    }
    return undefined;
}
function setSessionParticipations(participations, key) {
    storage.setSession(key, JSON.stringify(participations));
}
function clearParticipationsFromSession() {
    storage.setSession(PARTICIPATIONS_KEY, JSON.stringify({}));
    storage.setSession(LANDING_PAGE_PARTICIPATIONS_KEY, JSON.stringify({}));
}
export { clearParticipationsFromSession, getSessionParticipations, setSessionParticipations, PARTICIPATIONS_KEY, LANDING_PAGE_PARTICIPATIONS_KEY, };
