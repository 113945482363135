import { countryGroups } from 'helpers/internationalisation/countryGroup';
const HomeDelivery = 'HomeDelivery';
const NationalDelivery = 'NationalDelivery';
const Collection = 'Collection';
const Domestic = 'Domestic';
const RestOfWorld = 'RestOfWorld';
const NoFulfilmentOptions = 'NoFulfilmentOptions';
export { HomeDelivery, NationalDelivery, Collection, Domestic, RestOfWorld, NoFulfilmentOptions, };
const getWeeklyFulfilmentOption = (country) => countryGroups.International.countries.includes(country)
    ? RestOfWorld
    : Domestic;
export const getFulfilmentOptionFromProductKey = (productKey) => {
    switch (productKey) {
        case 'SupporterPlus':
        case 'GuardianAdLite':
        case 'Contribution':
        case 'OneTimeContribution':
        case 'DigitalSubscription':
        case 'GuardianPatron':
            return 'NoFulfilmentOptions';
        case 'TierThree':
        case 'GuardianWeeklyDomestic':
            return 'Domestic';
        case 'GuardianWeeklyRestOfWorld':
            return 'RestOfWorld';
        case 'SubscriptionCard':
            return 'Collection';
        case 'NationalDelivery':
        case 'HomeDelivery':
            return productKey;
    }
};
export { getWeeklyFulfilmentOption };
